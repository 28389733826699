<script>
import api from '@/helpers/api-rest/api.js';
// import { Swal } from 'sweetalert2/dist/sweetalert2';
import Swal from "sweetalert2";
import { generateValidations, onlyLetters, noSpecialChars, noSpaces } from "@/helpers/validations/validationsHelpers";
import { errorMessages } from "@/helpers/validations/errorMessages";
import { sameAs } from "vuelidate/lib/validators";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
// import Multiselect from "vue-multiselect";

export default {
    components: { vSelect },
    data() {
        return {
            selectProjectOptions: [],
            selectCenterOptions: [],
            userRegistered: {
                password: "",
                repassword: "",
                name: '',
                email: '',
                dni: '',
                center_id: '',
                project_id: '',
                use: ''
            },
            loading: false,
            errorMessages,
        };
    },
    validations: {
        userRegistered: generateValidations(
            ["name", "dni", "email", "password", "repassword", "center_id", "project_id", "use"],
            {
                name: [onlyLetters],
                dni: [noSpecialChars, noSpaces],
                email: [noSpaces],
                repassword: [sameAs("password")],
            }
        ),
    },
  
    methods: {
        successmsg() {
            Swal.fire("!Enhorabuena!", "Tu solicitud ha sido registrada correctamente. Se le enviará un email cuando se acepte su solicitud y podrá tener acceso a la plataforma", "success");
        },
        loadProjects(){
            const apiEndpoint = `/api/v1/list-register?status=1`;
            api.get({url:apiEndpoint})
            .then(response => {
                this.selectProjectOptions = response?.data?.data;
                console.log(this.selectProjectOptions);
            })
            .catch(error => {
                console.error("Error loading data:", error);
            });
        },
        loadCenters(){
            const apiEndpoint = `/api/v1/list-centers?status=1`;
            api.get({url:apiEndpoint})
            .then(response => {
                this.selectCenterOptions = response?.data?.data;
                console.log(this.selectCenterOptions);
            })
            .catch(error => {
                console.error("Error loading data:", error);
            });
        },
        registerUser() {
            this.loading = true;
            const apiEndpoint = `/api/v1/register`; 
            api.post({url:apiEndpoint, data:this.userRegistered})
                .then(response => {
                    this.loading = false;
                    const newUser = response?.data?.data;
                    this.$bvModal.hide("ModalRegister");
                    this.successmsg();
                })
                .catch(error => {
                    this.loading = false;
                    console.error("Error al crear el usuario:", error);
                });
        },

        submitForm() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.registerUser();
            
        },
        cancelForm() {
            this.$v.$reset();
            this.$bvModal.hide("ModalRegister");
            this.$emit('close-modal');
        },
        loadData(){
            this.loadProjects();
            this.loadCenters();
        }

    }
}
</script>
<template>
    <b-modal size="lg" id="ModalRegister" @shown="loadData" title="Registro" centered>
      <template #default="{  }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
            <div class="form-group">
                <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-user-line auti-custom-input-icon"></i>
                    <label for="name">Nombre <span style="color: red;">*</span></label>
                    <input type="text" v-model="userRegistered.name" class="form-control" id="name" placeholder="Introduce tu nombre" maxlength="200" :class="{ 'is-invalid': $v.userRegistered.name.$error }"/>
                    <div v-if="$v.userRegistered.name.$error" class="invalid-feedback">
                        <span v-if="!$v.userRegistered.name.required">{{ errorMessages.required }}</span>
                        <span v-else-if="!$v.userRegistered.name.onlyLetters">{{ errorMessages.onlyLetters }}</span>
                        <!-- <span v-else-if="!$v.userRegistered.name.noSpecialChars">{{ errorMessages.noSpecialChars }}</span> -->
                    </div>
                </div>
                <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-account-box-line auti-custom-input-icon"></i>
                    <label for="userDNI">DNI <span style="color: red;">*</span></label>
                    <input type="text" v-model="userRegistered.dni" class="form-control" id="userDNI" placeholder="Introduce DNI" maxlength="10" :class="{ 'is-invalid': $v.userRegistered.dni.$error }"/>
                    <div v-if="$v.userRegistered.dni.$error" class="invalid-feedback">
                        <span v-if="!$v.userRegistered.dni.required">{{ errorMessages.required }}</span>
                        <span v-else-if="!$v.userRegistered.dni.noSpecialChars">{{ errorMessages.noSpecialChars }}</span>
                    </div>
                </div>
                <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-mail-line auti-custom-input-icon"></i>
                    <label for="email">Email <span style="color: red;">*</span></label>
                    <input type="email" v-model="userRegistered.email" class="form-control" id="email" placeholder="Introduce email" :class="{ 'is-invalid': $v.userRegistered.email.$error }"/>
                    <div v-if="$v.userRegistered.email.$error" class="invalid-feedback">
                        <span v-if="!$v.userRegistered.email.required">{{ errorMessages.required }}</span>
                        <span v-else-if="!$v.userRegistered.email.email">{{ errorMessages.email }}</span>
                    </div>
                </div>
                <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-lock-2-line auti-custom-input-icon"></i>
                    <label for="password">Password <span style="color: red;">*</span></label>
                    <input v-model="userRegistered.password" type="password" class="form-control" id="password" placeholder="Introduce contraseña" :class="{ 'is-invalid': $v.userRegistered.password.$error }"/>
                    <div v-if="$v.userRegistered.password.$error" class="invalid-feedback">
                        <span v-if="!$v.userRegistered.password.required">{{ errorMessages.required }}</span>
                        <span v-else-if="!$v.userRegistered.password.minLength">{{ errorMessages.minLength($v.userRegistered.password.$params.minLength.min) }}</span>
                        <span v-else-if="!$v.userRegistered.password.maxLength">{{ errorMessages.maxLength($v.userRegistered.password.$params.maxLength.max) }}</span>
                    </div>
                </div>
                <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-lock-2-line auti-custom-input-icon"></i>
                    <label for="repassword">Confirm Password <span style="color: red;">*</span></label>
                    <input v-model="userRegistered.repassword" type="password" class="form-control" id="repassword" placeholder="Confirma contraseña" :class="{ 'is-invalid': $v.userRegistered.repassword.$error }"/>
                    <div v-if="$v.userRegistered.repassword.$error" class="invalid-feedback">
                        <span v-if="!$v.userRegistered.password.required">{{ errorMessages.required }}</span>
                        <div v-else-if="!$v.userRegistered.repassword.sameAs">{{ errorMessages.sameAs }}</div>
                    </div>
                </div>
                <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-community-line auti-custom-input-icon"></i>
                    <label for="userCenter">Centro <span style="color: red;">*</span></label>
                    <div class="form-control" :class="{ 'is-invalid': $v.userRegistered.center_id.$error }">
                        <v-select
                            id="selectProjectOptions"
                            v-model="userRegistered.center_id"
                            :options="selectCenterOptions"
                            :reduce="(option) => option.id"
                            label="name"
                            placeholder="Selecciona centro"
                            no-options-text="No se encontraron resultados"
                        ></v-select>
                    </div>
                    <!-- <select class="form-control" id="selectCenterOptions" v-model="userRegistered.center_id" :class="{ 'is-invalid': $v.userRegistered.center_id.$error }">
                        <option value="">Selecciona centro</option>
                        <option v-for="option in selectCenterOptions" :value="option.id" :key="option.id">{{ option.name }}</option>
                    </select> -->
                    <div v-if="$v.userRegistered.center_id.$error" class="invalid-feedback">
                        <span v-if="!$v.userRegistered.center_id.required">{{ errorMessages.required }}</span>
                    </div>
                </div>
                <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-article-line auti-custom-input-icon"></i>
                    <label for="userCenter">Convocatoria <span style="color: red;">*</span></label>
                    <div class="form-control" :class="{ 'is-invalid': $v.userRegistered.project_id.$error }">
                        <v-select
                            id="selectProjectOptions"
                            v-model="userRegistered.project_id"
                            :options="selectProjectOptions"
                            :reduce="(option) => option.id"
                            label="name"
                            placeholder="Selecciona convocatoria"
                            no-options-text="No se encontraron resultados"
                        ></v-select>
                    </div>
                    <!-- <select class="form-control" id="selectProjectOptions" v-model="userRegistered.project_id" :class="{ 'is-invalid': $v.userRegistered.project_id.$error }">
                        <option value="">Selecciona convocatoria</option>
                        <option v-for="option in selectProjectOptions" :value="option.id" :key="option.id">{{ option.name }}</option>
                    </select> -->
                    <div v-if="$v.userRegistered.project_id.$error" class="invalid-feedback">
                        <span v-if="!$v.userRegistered.project_id.required">{{ errorMessages.required }}</span>
                    </div>
                </div>
                <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-align-justify auti-custom-input-icon"></i>
                    <label for="userUse">Uso <span style="color: red;">*</span></label>
                    <textarea v-model="userRegistered.use" class="form-control" :maxlength="500" id="userUse" rows="5" placeholder="Introduce uso al que vas a destinar la plataforma" :class="{ 'is-invalid':  $v.userRegistered.use.$error }"></textarea>
                    <small class="text-muted">{{ userRegistered.use.length }}/500</small>
                    <div v-if="$v.userRegistered.use.$error" class="invalid-feedback">
                        <span v-if="!$v.userRegistered.use.required">{{ errorMessages.required }}</span>
                    </div>
                </div>
            </div>
        </form>
      </template>
  
      <template #modal-footer="{  }">
        <b-button variant="primary" @click="submitForm()" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>Registrarse</b-button>
        <b-button @click="cancelForm()">Cancelar</b-button>
      </template>
    </b-modal>
</template>